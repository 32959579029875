import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { P } from '@farewill/ui'
import PATHS from 'paths'

type ProductSectionsData = {
  heading: string
  subheading: string
  imageUrl: string
  imagePosition?: 'left' | 'right'
  description: ReactElement
}

export const PRODUCT_SECTIONS_DATA: ProductSectionsData[] = [
  {
    subheading: 'WILLS',
    heading: 'Bringing positive change to an outdated industry',
    description: (
      <>
        <P>
          We launched our{' '}
          <Link to={PATHS.WILLS.OVERVIEW}>will writing service</Link>, in 2016,
          with the aim of making it simpler and more affordable to write your
          will. We replaced expensive trips to a high street solicitor with
          innovative online tools and outstanding customer service.
        </P>
        <P>
          We’ve benchmarked ourselves against the best since then, winning
          National Will Writing Firm of the Year for four years running at the
          National Wills and Probate awards.
        </P>
      </>
    ),
    imageUrl: 'illustrations/blob-laptop-continue',
  },
  {
    subheading: 'PROBATE',
    heading: 'Cutting out the legal jargon',
    description: (
      <>
        <P>
          The more people chose to write their will with us, the more they asked
          for our help with probate too.
        </P>
        <P>
          {' '}
          So we launched our{' '}
          <Link to={PATHS.PROBATE.OVERVIEW}>award-winning probate service</Link>
          , in 2019. Instead of baffling you with legalese, we’ll listen and
          give you a personalised, fixed-fee quote. And together with our
          specialist legal partner, we’ll support you with your grant of probate
          application and through the estate administration process.
        </P>
      </>
    ),

    imageUrl: 'illustrations/estate-admin-house-paperwork',
    imagePosition: 'right',
  },
  {
    subheading: 'DIRECT CREMATIONS',
    heading: 'Supporting people to do things their way',
    description: (
      <>
        <P>
          Traditional funerals just aren’t the right fit for millions of people
          in the UK anymore. People want less fuss, more affordable prices, and
          most importantly, a funeral that truly represents them.
        </P>
        <P>
          In 2020, we launched our{' '}
          <Link to={PATHS.CREMATION.DIRECT}>direct cremation service</Link>,
          supporting people to do things their way. Since then we’ve won Best
          Direct Cremation Provider 2022, and Low Cost Funeral Provider of the
          Year for two years running.
        </P>
        <P>
          In 2024, we launched our{' '}
          <Link to={PATHS.CREMATION.FUNERAL_PLANS}>funeral plan</Link>, so that
          people can pay for a direct cremation in advance and get the same
          exceptional level of service.
        </P>
      </>
    ),
    imageUrl: 'illustrations/funerals-celebration-music-peace',
  },
]
