import React from 'react'
import styled from 'styled-components'
import { Grid, P, Wrapper, Image, H } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { GTR, COLOR } from '@farewill/ui/tokens'
import WrapperWithEdge from 'components/WrapperWithEdge'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'

const StyledAwardsGridItem = styled(Grid.Item)`
  align-items: flex-end;
  display: flex;
  justify-content: center;
`

const StyledAwardsGrid = styled(Grid)`
  ${screenMin.l`
    column-gap: ${GTR.XL};
  `}

  & > div {
    height: 100%;
  }
`

const StyledWrapper = styled(Wrapper)`
  padding-bottom: 0;
`

const Awards = () => (
  <WrapperWithEdge direction="down" creamAccent thickEdge>
    <StyledWrapper container>
      <H tag="h2" size="L" decorative centered paddingFromM={[0, 0, 'XL']}>
        Our award-winning services
      </H>
      <StyledAwardsGrid gap="XS" gapFromL="S" centered>
        <StyledAwardsGridItem spanFromL={3} centered>
          <Image
            path="external-logos/wills-and-probate-awards-no-year"
            width={166}
            height={85}
          />
        </StyledAwardsGridItem>
        <Grid.Item
          spanFromL={3}
          startRowFromL={2}
          maxWidth={265}
          margin="auto"
          centered
        >
          <P size="S" color={COLOR.BLACK} margin={['S', 0, 'M']} strong>
            National Will Writing Firm of the Year 2019 • 2020 • 2021 • 2022
          </P>
          <P size="S" color={COLOR.BLACK} strong>
            Probate Provider of the Year 2020&nbsp;•&nbsp;2021
          </P>
        </Grid.Item>
        <StyledAwardsGridItem
          spanFromL={3}
          centered
          padding={['XL', 0, 0]}
          paddingFromL={0}
        >
          <Image
            path="external-logos/good_funeral_awards_light"
            width={140}
            height={112}
          />
        </StyledAwardsGridItem>
        <Grid.Item
          spanFromL={3}
          startRowFromL={2}
          maxWidth={265}
          margin="auto"
          centered
        >
          <P size="S" color={COLOR.BLACK} margin={['S', 0, 'M']} strong>
            Low-cost Funeral Provider of the Year 2021 • 2022
          </P>
          <P size="S" color={COLOR.BLACK} strong>
            Best Direct Cremation Provider 2022
          </P>
        </Grid.Item>
        <StyledAwardsGridItem
          spanFromL={3}
          centered
          padding={['XL', 0, 0]}
          paddingFromL={0}
        >
          <Image path="external-logos/sme-awards" width={131} height={82} />
        </StyledAwardsGridItem>
        <Grid.Item
          spanFromL={3}
          startRowFromL={2}
          maxWidth={265}
          margin="auto"
          centered
        >
          <P size="S" color={COLOR.BLACK} margin={['S', 0, 'M']} strong>
            Wills & Probate Firm of the Year 2024
          </P>
          <P size="S" color={COLOR.BLACK} strong>
            UK Enterprise Customer Care Excellence Award 2024
          </P>
        </Grid.Item>
        <StyledAwardsGridItem
          spanFromL={3}
          centered
          padding={['XL', 0, 0]}
          paddingFromL={0}
        >
          <Image
            path="external-logos/trustpilot-score"
            width={131}
            height={82}
          />
        </StyledAwardsGridItem>
        <Grid.Item
          spanFromL={3}
          startRowFromL={2}
          maxWidth={265}
          margin="auto"
          centered
        >
          <P size="S" color={COLOR.BLACK} margin={['S', 0, 'M']} strong>
            Rated Excellent on Trustpilot <br />
            {TRUSTPILOT_RATING_OUT_OF_5}/5 with over{' '}
            {roundDownToNearestHundred(TRUSTPILOT_REVIEWS_TOTAL)} reviews
          </P>
        </Grid.Item>
      </StyledAwardsGrid>
    </StyledWrapper>
  </WrapperWithEdge>
)

export default Awards
