const DAN_GARRETT = {
  name: 'Dan Garrett',
  job: 'CEO',
  imageUrl: 'e_grayscale/photos/headshots/dan-garrett-headshot',
}

const TRACY_DOREE = {
  name: 'Tracy Doree',
  job: 'Chair',
  imageUrl: 'e_grayscale/photos/headshots/tracy-doree',
}

const TIM_LEVENE = {
  name: 'Tim Levene',
  job: 'Non-Executive Director, and investor',
  imageUrl: 'e_grayscale/photos/headshots/tim-levine',
}

const STAN_LAURENT = {
  name: 'Stan Laurent',
  job: 'Non-Executive Director, and investor',
  imageUrl: 'e_grayscale/photos/headshots/stan-laurent',
}

const AMY_CHICHESTER = {
  name: 'Amy Chichester',
  job: 'VP, Finance',
  imageUrl: 'e_grayscale/photos/headshots/amy-chichester',
}

const MATT_MORGAN = {
  name: 'Matt Morgan',
  job: 'Head of Funeral',
  imageUrl: 'e_grayscale/photos/headshots/matt-morgan',
}

const DOUG_STRACHAN = {
  name: 'Doug Strachan',
  job: 'Director',
  imageUrl: 'e_grayscale/photos/headshots/doug-strachan',
}

export const DIRECTORS = [DAN_GARRETT, TRACY_DOREE, TIM_LEVENE, STAN_LAURENT]

export const FAREWILL_FUNERALS_DIRECTORS = [
  DAN_GARRETT,
  AMY_CHICHESTER,
  TIM_LEVENE,
  MATT_MORGAN,
  DOUG_STRACHAN,
]
