import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Image, P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import PATHS from 'paths'
import TextBox from '../TextBox'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
  z-index: 1;
`

const StyledTextureA = styled(Image)`
  position: absolute;
  left: -200px;
  top: 10%;
  transform: scale(1, -1);
  opacity: 0.2;
  z-index: -1;

  ${screenMax.m`
    left: -150px;
  `}

  ${screenMax.s`
    display: none;
  `}
`

const StyledTextureB = styled(Image)`
  position: absolute;
  left: -140px;
  top: 30%;
  z-index: -1;

  ${screenMax.m`
    left: -100px;
    top: 10%;
  `}

  ${screenMax.s`
    display: none;
  `}
`

const StyledTextureC = styled(Image)`
  position: absolute;
  right: -200px;
  bottom: 10%;
  transform: scale(-1, -1);
  opacity: 0.2;
  z-index: -1;

  ${screenMax.l`
    right: -100px;
    bottom: 2%;
  `}
`

const StyledTextureD = styled(Image)`
  position: absolute;
  right: -50px;
  bottom: 20%;
  transform: scale(-1, 1);
  z-index: -1;

  ${screenMax.m`
    bottom: 2%;
  `}
`

const StyledImage = styled(Image)`
  ${screenMax.s`
    width: 100%;
  `}

  ${screenMin.l`
    max-width: 360px !important;
  `}
`

const People = (): ReactElement => (
  <StyledWrapper background={COLOR.ACCENT.PRIMARY} paddingFromM={['XL', 0]}>
    <StyledTextureA
      path="textures/blob-white-1"
      width={200}
      widthFromL={400}
      loading="auto"
    />
    <StyledTextureB
      path="textures/detail_new_yellow02"
      width={150}
      widthFromL={300}
      loading="auto"
    />
    <StyledTextureC
      path="textures/blob-white-1"
      width={300}
      widthFromL={400}
      loading="auto"
    />
    <StyledTextureD
      path="e_trim/textures/detail-1"
      width={180}
      widthFromL={250}
      loading="auto"
    />
    <Wrapper container>
      <TextBox
        image={
          <StyledImage
            path="com-assets/farewill_exec_team"
            ext="jpg"
            width={320}
            widthFromM={720}
            heightFromM={640}
          />
        }
        subheading="Our People"
        subheadingColor={COLOR.BLACK}
        heading="An award-winning team of specialists"
        imageDescription="The Farewill executive team, from left to right: Jenny, Dan, Michael, Nicci and Melissa"
        imagePositionMobile="bottom"
        imagePosition="left"
      >
        <P color={COLOR.BLACK}>
          Spread across the UK, we’re a team of legal and funeral specialists,
          combined with product and software experts – all with the shared goal
          of making things simpler and more accessible for our customers.
        </P>
        <P color={COLOR.BLACK}>
          Our head office is in London, which is where we’ve been from the
          start. Find out more about{' '}
          <Link to={PATHS.GENERAL.CAREERS}>working at Farewill</Link>.
        </P>
      </TextBox>
    </Wrapper>
  </StyledWrapper>
)

export default People
