import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Image, P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import TextBox from '../TextBox'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
  z-index: 1;
`

const StyledTextureA = styled(Image)`
  position: absolute;
  left: -210px;
  top: 15%;
  transform: rotate(-45deg);
  z-index: -1;

  ${screenMax.m`
    top: 40%;
  `}

  ${screenMax.s`
    display: none;
  `}
`

const StyledTextureB = styled(Image)`
  position: absolute;
  left: -50px;
  top: 35%;
  z-index: -2;

  ${screenMax.m`
    top: 50%;
  `}

  ${screenMax.s`
    display: none;
  `}
`

const StyledTextureC = styled(Image)`
  position: absolute;
  right: -60px;
  top: 20%;
  z-index: -1;

  ${screenMax.s`
    display: none;
  `}
`

const StyledImage = styled(Image)`
  ${screenMax.s`
    width: 100%;
  `}

  ${screenMin.l`
    max-width: 360px !important;
  `}
`

const Mission = (): ReactElement => (
  <StyledWrapper background={COLOR.ACCENT.PRIMARY} paddingFromM={['XL', 0]}>
    <StyledTextureA
      path="textures/fingerprint-yellow-03_rolgha"
      width={350}
      widthFromL={450}
      loading="auto"
    />
    <StyledTextureB
      path="textures/sketchy-swoop-yellow-02_ha58tm"
      width={150}
      widthFromL={200}
      loading="auto"
    />
    <StyledTextureC
      path="textures/detail_new_yellow02-light"
      width={200}
      widthFromL={250}
      loading="auto"
    />

    <Wrapper container>
      <TextBox
        image={
          <StyledImage
            path="illustrations/old-fashioned-lawyer_gloomy"
            ext="png"
            width={320}
            widthFromM={720}
            heightFromM={640}
          />
        }
        subheading="Our Mission"
        subheadingColor={COLOR.BLACK}
        heading="Changing the way the world deals with death"
        imagePositionMobile="bottom"
        imagePosition="left"
      >
        <P color={COLOR.BLACK}>
          From writing your will to planning a funeral, the way we deal with
          death is formal, complicated and expensive. Instead of feeling
          supported, people feel lost.
        </P>
        <P color={COLOR.BLACK}>
          But it doesn’t need to be this way. We started Farewill to change the
          way the world deals with death: to make the experience warmer,
          brighter and friendlier.
        </P>
      </TextBox>
    </Wrapper>
  </StyledWrapper>
)

export default Mission
