import React from 'react'

import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import DefaultLayout from 'layouts/DefaultLayout'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'
import { TAGS } from 'lib/trustpilot/constants'
import Hero from './components/Hero'
import People from './components/People'
import Mission from './components/Mission'
import Awards from './components/Awards'
import Investors from './components/Investors'
import BoardOfDirectors from './components/BoardOfDirectors'
import Products from './components/Products'
import CharityPartners from './components/CharityPartners'

const About = (): React.ReactElement => (
  <Wrapper background={COLOR.WHITE}>
    <DefaultLayout
      title="About us"
      description="Find out how we’re changing the way the world deals with death."
    >
      <Wrapper>
        <Hero />
        <Mission />
        <Awards />
        <Products />

        <TrustpilotCarousel tag={TAGS.FAVOURITES_ALL_PRODUCTS} />
        <CharityPartners />
        <People />
        <Investors />
        <BoardOfDirectors />
        <Wrapper paddingFromXL={[0, 0, 'XXL']} />
      </Wrapper>
    </DefaultLayout>
  </Wrapper>
)

export default About
