import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Image, P, Wrapper } from '@farewill/ui'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import PATHS from 'paths'
import { CHARITY_AMOUNT_RAISED } from 'config'
import CharitiesList from 'components/CharitiesList'
import TextBox from '../TextBox'

const StyledImage = styled(Image)`
  ${screenMax.s`
    width: 100%;
  `}

  ${screenMin.l`
    max-width: 360px !important;
  `}
`

const CharityPartners = (): ReactElement => (
  <Wrapper container paddingFromL={['XL', 'XL', 'XXL']}>
    <Wrapper paddingFromXL={[0, 0, 'XXL']}>
      <TextBox
        image={
          <StyledImage
            path="illustrations/plant-a-tree"
            width={320}
            widthFromM={720}
            heightFromM={640}
          />
        }
        subheading="Our charity partners"
        heading="Legacies that last for generations"
        imagePositionMobile="bottom"
        imagePosition="right"
      >
        <P>
          Since our online launch in 2016, our generous customers have pledged
          {CHARITY_AMOUNT_RAISED} to charities, through donations left in their
          wills.
        </P>
        <P>
          From saving lives at sea to specialist support through a life-changing
          illness, we’re proud to help people leave a legacy that will last for
          generations.
        </P>
        <P>
          Find out about{' '}
          <Link to={PATHS.GENERAL.CHARITIES}>
            becoming a Farewill charity partner
          </Link>
          .
        </P>
      </TextBox>
    </Wrapper>
    <Wrapper
      padding={['M', 0, 'S']}
      paddingFromL={['XXL', 0, 0]}
      paddingFromXL={['XXL', 0]}
    >
      <CharitiesList spreadItems />
    </Wrapper>
  </Wrapper>
)

export default CharityPartners
