import React, { ReactElement } from 'react'
import { Wrapper, Image } from '@farewill/ui'
import styled from 'styled-components'
import { screenMax } from '@farewill/ui/helpers/responsive'

import TextBox from '../TextBox'
import { PRODUCT_SECTIONS_DATA } from './data'

const StyledImage = styled(Image)`
  ${screenMax.s`
    width: 100%;
  `}
`

const Products = (): ReactElement => (
  <Wrapper container containerVerticalPadding={0}>
    <Wrapper
      padding={['M', 0, 'S']}
      paddingFromL={['XL', 0, 'S']}
      paddingFromXL={['XXL', 0, 0]}
    >
      {PRODUCT_SECTIONS_DATA.map((productSection) => (
        <Wrapper
          key={`${productSection.heading} wrapper`}
          paddingFromL={['L', 0]}
        >
          <TextBox
            image={
              <StyledImage
                path={productSection.imageUrl}
                ext="png"
                width={350}
                widthFromM={600}
                heightFromM={600}
              />
            }
            subheading={productSection.subheading}
            heading={productSection.heading}
            imagePosition={productSection.imagePosition || 'left'}
            imagePositionMobile="bottom"
            key={productSection.heading}
          >
            {productSection.description}
          </TextBox>
        </Wrapper>
      ))}
    </Wrapper>
  </Wrapper>
)

export default Products
