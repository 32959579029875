import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import { DIRECTORS, FAREWILL_FUNERALS_DIRECTORS } from './data'

const StyledFlex = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 800px) {
    justify-content: space-between;
    padding: 0 ${GTR.M};
  }

  ${screenMin.l`
    justify-content: center;
    padding: 0 ;
  `}
`

const StyledH = styled(H)`
  ${screenMax.s`
    font-size: ${FONT.SIZE.L}
  `}
`

const StyledWrapper = styled(Wrapper)`
  border-radius: ${GTR.S};
`

const StyledBoardMember = styled(Wrapper)`
  justify-content: center;
  display: flex;
  text-align: center;
  flex-basis: 100%;
  max-width: 100%;

  ${screenMin.m`
    flex-basis: 50%;
  `}

  @media (min-width: 800px) {
    flex-basis: 25%;
  }

  ${screenMin.xl`
    flex-basis: 20%;
  `}
`

const Director = ({
  director,
}: {
  director: {
    name: string
    job: string
    imageUrl: string
  }
}) => (
  <StyledBoardMember
    key={director.name}
    margin={['M', 0]}
    paddingFromL={[0, 'XS']}
    paddingFromXL={[0, 'S']}
  >
    <Wrapper centered maxWidth={190}>
      <Image
        path={director.imageUrl}
        ext="png"
        width={75}
        alt={`Headshot of ${director.name}`}
      />
      <H size="XS" color={COLOR.GREY.DARK} margin={['XS', 0, 0]}>
        {director.name}
      </H>
      <P color={COLOR.GREY.DARK}>{director.job}</P>
    </Wrapper>
  </StyledBoardMember>
)

const BoardOfDirectors = (): ReactElement => (
  <Wrapper container centered>
    <StyledWrapper
      background={COLOR.ACCENT.PRIMARY_20}
      padding={['M', 0]}
      paddingFromL={['XL', 0, 0]}
    >
      <StyledH tag="h2" size="S">
        Farewill board of directors
      </StyledH>
      <StyledFlex margin={['M', 'auto']} noTrim>
        {DIRECTORS.map((director) => (
          <Director director={director} key={director.name} />
        ))}
      </StyledFlex>
      <StyledH tag="h2" size="S">
        Farewill Funerals board of directors
      </StyledH>
      <StyledFlex margin={['M', 'auto']} noTrim>
        {FAREWILL_FUNERALS_DIRECTORS.map((director) => (
          <Director director={director} key={director.name} />
        ))}
      </StyledFlex>
    </StyledWrapper>
  </Wrapper>
)

export default BoardOfDirectors
