import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { ReactNode } from 'react-markdown'
import { Grid, H, P, Wrapper } from '@farewill/ui'
import { smallCaps } from '@farewill/ui/helpers/text'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

const StyledImageDescription = styled.span`
  display: inline-block;
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.BLACK};
  text-align: left;
  padding: 4px;

  ${screenMin.l`
  max-width: 340px;
  position: absolute;
  left: 0;
  top: 100%;
  `}
`

const StyledSubheading = styled(P)`
  ${smallCaps};
  padding-bottom: ${GTR.XS};
  display: inline-block;
`

const StyledImageWrapper = styled(Grid.Item)<{
  $imagePosition: 'left' | 'right'
}>`
  ${({ $imagePosition }) =>
    $imagePosition === 'right'
      ? css`
          ${screenMin.l`
            padding-left: ${GTR.M};
            order: 2;
          `}
        `
      : css`
          ${screenMin.l`
            padding-right: ${GTR.M};
          `}
        `};

  ${screenMax.m`
    margin-left: 0px;
  `}

  margin-left: auto;
  margin-right: auto;
  position: relative;

  ${screenMax.m`
    margin-left: 0px;
  `}

  img {
    max-width: 100%;
    max-height: 480px;
    object-fit: cover;
  }
`

const StyledGrid = styled(Grid)`
  align-items: center;
`

const StyledContent = styled(Grid.Item)`
  ${({ $imagePosition }) =>
    $imagePosition === 'right' &&
    `
    order: 1
  `}
`

type TextBoxProps = {
  className?: string
  image: ReactElement
  imagePosition?: 'left' | 'right'
  imagePositionMobile?: 'top' | 'bottom'
  imageDescription?: string
  subheading?: string
  subheadingColor?: string
  heading: string
  headingImage?: ReactElement
  children?: ReactNode
}

const TextBox = ({
  className,
  children,
  image,
  imagePosition,
  imagePositionMobile = 'top',
  imageDescription,
  subheading,
  subheadingColor,
  heading,
  headingImage,
}: TextBoxProps): ReactElement => (
  <StyledGrid className={className} padding={['S', 0]} paddingFromM={0}>
    <StyledImageWrapper
      centeredFromL
      spanFromL={5}
      startRow={imagePositionMobile === 'top' ? 1 : 2}
      startRowFromL={1}
      startColumnFromL={imagePosition === 'right' ? 7 : 1}
      startColumnFromXL={imagePosition === 'right' ? 7 : 2}
      $imagePosition={imagePosition}
    >
      {image}
      {imageDescription && (
        <StyledImageDescription>{imageDescription}</StyledImageDescription>
      )}
    </StyledImageWrapper>
    <StyledContent
      spanFromL={6}
      spanFromXL={5}
      startRow={imagePositionMobile === 'top' ? 2 : 1}
      startRowFromL={1}
      startColumnFromL={imagePosition === 'right' ? 1 : 7}
      startColumnFromXL={imagePosition === 'right' ? 2 : 7}
      $imagePosition={imagePosition}
    >
      {headingImage && <Wrapper margin={[0, 0, 'L']}>{headingImage}</Wrapper>}
      {subheading && (
        <StyledSubheading color={subheadingColor || COLOR.GREY.DARK}>
          {subheading}
        </StyledSubheading>
      )}
      <H tag="h2" size="M" decorative>
        {heading}
      </H>
      {children}
    </StyledContent>
  </StyledGrid>
)

export default TextBox
