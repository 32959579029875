import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Image, P, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import TextBox from '../TextBox'

const StyledImage = styled(Image)`
  ${screenMax.s`
    width: 100%;
  `}
`

const Investors = (): ReactElement => (
  <Wrapper container>
    <TextBox
      image={
        <StyledImage
          path="illustrations/turtle-with-money"
          ext="png"
          width={320}
          widthFromM={720}
          heightFromM={640}
        />
      }
      subheading="Our investors"
      heading="Backed by the best"
      imagePositionMobile="bottom"
      imagePosition="left"
    >
      <P>
        Our investors are as excited about our mission as we are. Farewill is
        backed and supported by some of the best investors and entrepreneurs in
        Europe, from the founders of TransferWise and Headspace, to Highland
        Europe, Augmentum Fintech, Kindred Capital, JamJar, DMG Ventures,
        Broadhaven Capital and SAATCHiNVEST.
      </P>
    </TextBox>
  </Wrapper>
)

export default Investors
